import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ComingSoon = () => (
  <Layout>
    <SEO title="ComingSoon" />
    <div className="container pt-5 text-center">
            <h1 className="mb-6">Coming Soon</h1>
            <p class="mb-5">This section is still under construction, come back soon!</p>
            <Link to="/">Go back to the homepage</Link>
    </div>
  </Layout>
)

export default ComingSoon
